import { Fragment, ReactNode, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Flex, Typography } from 'antd';
import moment from 'moment';
import clsx from 'clsx';

import styles from './RouteFeedItem.module.scss';
import { Route as RouteIcon } from 'shared/ui/icons/route';
import { ArrowRight as ArrowRightIcon } from 'shared/ui/icons/arrow-right';
import { Calendar as CalendarIcon } from 'shared/ui/icons/calendar';
import { Car as CarIcon } from 'shared/ui/icons/car';
import { Wallet as WalletIcon } from 'shared/ui/icons/wallet';
import { RouteModel } from 'models/RouteModel';

interface Props {
  feed: RouteModel,
}

export const RouteFeedItem = ({ feed }: Props) => {
  const img = feed.points[0].images[0];
  const price = useMemo(() => {
    const result: ReactNode[] = [];
    const price = String(feed.price).split('').reverse();
    for (let i = 0; i < price.length; i++) {
      const char = price[i];
      if (i % 3 === 0) {
        result.push(
          <span
            key={i}
            style={{
              marginRight: '2px',
            }}
          >
            {char}
          </span>
        );
      } else {
        result.push(
          <Fragment key={i}>{char}</Fragment>
        );
      }
    }
    return result.reverse();
  }, [feed.price]);

  const handleShowMoreButtonClick  = () => {

  };

  return (
    <article className={clsx(styles.wrapper)}>
      <Flex
        vertical
        className={clsx(styles.content)}
      >
        <Flex
          align="center"
          className={clsx(styles.row)}
        >
          <RouteIcon className={clsx(styles.routeIcon)} />
          <Typography.Title
            level={2}
            ellipsis={{
              rows: 2,
            }}
          >
            {feed.points.map(({ title }, i) => {
              const isLastItem = i + 1 === feed.points.length;
              return (
                <Fragment key={i}>
                  {title}
                  {!isLastItem && (
                    <ArrowRightIcon className={clsx(styles.titleArrowIcon)} />
                  )}
                </Fragment>
              );
            })}
          </Typography.Title>
        </Flex>
        <Flex
          align="center"
          className={clsx(styles.row)}
        >
          <CalendarIcon />
          <div className={clsx(styles.feedDate)}>
            {moment(feed.date).format('DD MMMM YYYY')}
          </div>
        </Flex>
        <Flex
          align="center"
          className={clsx(styles.row)}
        >
          <Typography.Paragraph
            className={clsx(styles.description)}
            ellipsis={{ rows: 4 }}
          >
            {feed.description}
          </Typography.Paragraph>
        </Flex>
        <Flex
          align="center"
          className={clsx(styles.row)}
        >
          <CarIcon />
          <div className={clsx(styles.feedCaption)}>
            <Typography.Text>Водителей на маршруте:</Typography.Text>
            <NavLink
              to=""
              className={clsx(styles.driversLink)}
            >
              12
            </NavLink>
          </div>
        </Flex>
        <div className={clsx(styles.separatorLine)} />
        <Flex
          align="center"
          justify="space-between"
        >
          <Flex align="center">
            <WalletIcon />
            <Typography.Text className={clsx(styles.price)}>
              от {price} ₽
            </Typography.Text>
          </Flex>
          <div
            // to={feed.id!}
            className={clsx(styles.openButton)}
            onClick={handleShowMoreButtonClick}
          >
            ПОДРОБНЕЕ
          </div>
        </Flex>
      </Flex>
    </article>
  );
};
